import "@babel/polyfill";
import App from "./App.vue";
import Vue from "vue";
import "@/registerServiceWorker";
import router from "@/router";
import store from "@/store";
import i18n from "@/i18n";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/scss/bootstrap.scss";
import "../../assets/scss/main.scss";

import { secondServise } from "@/service/time-servise";
import { getImage, getWebImage } from "@/service/getImage";
import { curentIndex } from "@/service/getCurentIndex";
import VueMeta from "vue-meta";
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.prototype.$second = secondServise;
Vue.prototype.$getImage = getImage;
Vue.prototype.$getWebImage = getWebImage;
Vue.prototype.$getLocaleIndex = curentIndex;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
